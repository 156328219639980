@import "variables";


.select2-container--bootstrap-5 .select2-dropdown {
  color: $color-dark !important;
  border-color: #98ab97 !important;

}


.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--highlighted {

  background-color: #e9efe9 !important;
}


.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {

  background-color: $color-dark !important;
}


.select2-container--bootstrap-5 .select2-selection {

  color: $color-dark !important;
  background-color: #fff !important;
  border: 1px solid $color-border-default !important;

}

.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field:focus {
  border-color: $color-border-focus !important;
  box-shadow: none !important;
}

.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {

  color: $color-dark !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options:not(.select2-results__options--nested) {
  max-height: 20rem !important;

}


.select2-container--bootstrap-5.select2-container--focus .select2-selection, .select2-container--bootstrap-5.select2-container--open .select2-selection {
  border-color: $color-border-focus !important;
  box-shadow: none !important;
}

.og-input-group .select2-container {
  width: 100%!important;
  margin-bottom: 1rem!important;

  @media (min-width: 576px) {

    margin-bottom: 0 !important;
    width: 0!important;
  }
}



.og-input-group2 .select2-container {
  width: 100%!important;
  margin-bottom: 1rem!important;

  @media (min-width: 992px) {

    margin-bottom: 0 !important;
    width: 0!important;
  }
}