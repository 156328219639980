@import "../variables";

.filled-button {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  min-height: 40px;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background-color: $color-dark;
  border: 5px solid $color-dark;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  padding: 3px 7px;
  white-space: nowrap;
  width: 100%;

  @media (min-width: 576px) {
    width: auto;
  }
  @media (min-width: 768px) {
    padding: 3px 16px;
    min-width: initial;
  }

  @media (min-width: 992px) {
    padding: 3px 21px;
    min-width: initial;
  }

  &:disabled, &.disabled {
    cursor: default;
    pointer-events: none;

    background-color: $color-grey;
    border-color: $color-grey;
    color: $color-accent;
  }

  &:not(:disabled):hover {
    background-color: transparent;
    color: $color-dark;
    text-decoration: none;
  }

  &:not(:disabled):active {
    background-color: $color-accent;
    border-color: $color-accent;
    color: rgb(255, 255, 255);
  }

}




.filled-button-large {
  padding: 3px 48px;
}

.button-width-100 {
  width: 100%;
}


.btn-g {
  color: $color-content-link;
}

.btn-red {
  background-color: $color-burgundian;
  border: 5px solid $color-burgundian;

  &:not(:disabled):hover {
    color: $color-burgundian;
  }
}


.filled-button-light {
  background-color: $color-content-link;
  border: 5px solid $color-content-link;
  color: $color-dark;

  &:not(:disabled):hover {
    background-color: transparent;
    color: $color-content-link;
  }

  &:not(:disabled):active {
    background-color: rgb(132, 153, 127);
    border-color: rgb(128, 153, 127);
    color: rgb(255, 255, 255);
  }

  &:disabled {
    background-color: rgb(216, 224, 216);
    border-color: rgb(216, 224, 216);
    color: rgb(127, 153, 137);
  }
}
