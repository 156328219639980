@import "variables";
@import "utils";
@import "select2-mod";
@import "bootstrap-mod";
@import "page-limit-mod";
@import "buttons/filled-button";
@import "buttons/outline-button";

//@import "node_modules/bootstrap/scss/functions";
//@import "node_modules/bootstrap/scss/variables";
//@import "node_modules/bootstrap/scss/mixins";
//@import "node_modules/bootstrap/scss/utilities";


// Импорт Bootstrap
//@import "node_modules/bootstrap/scss/bootstrap";

//@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: "Bondi";
  src: url("/static/fonts/bondi.otf");
}


input, button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0px;
}

button {
  overflow: visible;
  border: none;
  padding: 0px;
  outline: none;
  font-weight: inherit;
}

button {
  text-transform: none;
}

body {
  color: $color-dark;
}


@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 100 900; /* Включает диапазон весов от 100 до 900 */
  font-display: swap;
  src: url('/static/fonts/Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('/static/fonts/Nunito/Nunito-Italic-VariableFont_wght.ttf') format('truetype');
}


@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 100 900; /* Поддержка всех весов от 100 до 900 */
  font-display: swap;
  src: url('/static/fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
}

/* Курсив Nunito Sans */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('/static/fonts/Nunito_Sans/NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
}


/* Roboto - Thin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
}

/* Roboto - Thin Italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
}

/* Roboto - Light */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

/* Roboto - Light Italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

/* Roboto - Regular */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

/* Roboto - Italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

/* Roboto - Medium */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

/* Roboto - Medium Italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}

/* Roboto - Bold */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

/* Roboto - Bold Italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

/* Roboto - Black */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-Black.ttf') format('truetype');
}

/* Roboto - Black Italic */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('/static/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  font-family: Roboto, Arial, sans-serif;
}


#content-wrapper {
  padding-top: 54px;
  min-height: 80vh;

  @media (min-width: 768px) {
    padding-top: 72px;
  }
}

button.widget-fab {
  bottom: 120px !important;
}

#content {
  min-height: 50vh;
}

#wrapper #content-wrapper {
  background-color: $color-light-green-grey;
  width: 100%;
  overflow-x: hidden;
}

.olx-gpt-logo-link {
  color: $color-content-link;
  text-decoration: none;
  margin-left: 1rem;

  &:hover {
    text-decoration: none;
    color: $color-content-link;
  }

  @media (min-width: 768px) {
    margin-left: 0;
  }

}

.olx-gpt-logo-container {

  display: flex;
  align-items: center;
}


footer.sticky-footer {
  padding: 2rem 0;
  flex-shrink: 0
}

footer.sticky-footer .copyright {
  line-height: 1;
  font-size: .8rem
}

.olx-gpt-logo-full {
  width: 181px;
  display: none;

  svg {
    overflow: initial;
  }

  @media (min-width: 576px) {
    display: block;
  }

}

.olx-gpt-logo-icon {
  width: 53px;
  display: block;
  @media (min-width: 576px) {
    display: none;
  }
}

.olx-gpt-logo-txt {
  font-family: "Bondi";
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 24px;
  display: none;

  @media (min-width: 576px) {
    display: block;
  }

  @media (min-width: 781px) {
    font-size: 28px;
  }


}


.app-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  background: $color-dark;
  transition: top 0.4s ease-in-out;
}


.app-header-container {
  height: 72px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.button-container {
  display: flex;
  flex-direction: column;


  align-items: center;
  width: 100%;

  @media (min-width: 576px) {
    align-items: flex-end;
    width: auto;
  }

  &:nth-of-type(even) {
    margin: 1rem 0 0 0;

    @media (min-width: 576px) {
      margin: 0 0 0 1rem;
    }

  }
}

::selection {
  background: $color-burgundian;
  color: white;
}

.packages-button-container {
  margin: 0;

  @media (min-width: 576px) {
    margin: 0px 0px 0px 16px;
  }
}


.custom-alert-container {

  padding: 0 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  bottom: 25px;
  top: auto;

  @media (max-width: 576px) {
    top: 25px;
    bottom: auto;
  }

}


.big-page-name {
  text-transform: uppercase;
  color: $color-dark;
  font-weight: 700;
  font-size: calc(1.3rem + .6vw);
  text-wrap: nowrap;

  @media (max-width: 576px) {
    font-size: calc(1.275rem + .3vw);
  }
}

.top-page-container {
  display: flex;
  align-content: center;
  justify-content: normal;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  flex-direction: column;

  @media (min-width: 992px) {
    justify-content: space-between;
    flex-direction: row;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.top-page-balance-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 0;

  @media (min-width: 576px) {
    margin-top: 1rem;
  }
  @media (min-width: 992px) {
    margin-top: 0;
  }


}

.balance-container-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: 576px) {
    width: auto;
  }

  @media (min-width: 768px) {
    width: auto;
    flex-direction: row;
  }
}


.user_balance {
  margin-right: 16px;
  font-size: 14px;

}

.balance_container {
  display: flex;

  margin-bottom: 1rem;
  margin-top: 1.25rem;
  align-items: normal;
  flex-direction: row;
  justify-content: center;

  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-top: 0;
    //align-items: center;
    flex-direction: column;
    justify-content: flex-end;
  }

  @media (min-width: 992px) {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.app-header-container {
  height: 54px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  @media (min-width: 768px) {
    height: 72px;
  }
}


.header-nav-container {

  display: flex;
  align-items: center;
}


.header-messages-nav-container {

  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: $color-content-link;
}

.header-messages-nav-container:hover, .language-nav-container:hover {
  color: $color-content-link-hover;
  text-decoration: none;
}


.nav-container {
  position: relative;
  margin-right: 20px;


  @media (min-width: 768px) {
    margin-right: 30px;
  }

  @media (min-width: 991px) {
    margin-right: 40px;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.profile-nav-container {
    margin-right: 0;
  }

}


.messages-nav-text, .language-nav-text {
  margin-top: 0;
  margin-left: 0;
  text-wrap: nowrap;
}

.language-nav-text {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: $color-content-link;
}

.language-nav-text:hover {
  color: $color-content-link-hover;
}

.css-1lxhyof {
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 40px;
  color: #7F9799;
  font-size: 14px;
}


.css-1lxhyof a {
  text-decoration: none;
  cursor: pointer;
  color: #FFFFFF;
}

.css-1lxhyof a:hover {
  text-decoration: underline;
}


.css-10ktcbo:not(:first-of-type)::before {
  content: '|';
  padding: 0 12px;
  color: #7F9799;
}


.css-bkyudi {
  margin-right: 40px;
  position: relative;
}


.css-l9chfa {
  height: 22px;
  width: 22px;
  color: $color-dark;

}

.css-l9chfa:hover {
  color: #7F9799;
}


.header-profile-nav-container, .language-nav-container {
  position: relative;
}

.header-profile-nav-container a, .language-nav-container a, .profile-nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  color: $color-content-link;
}


.profile-nav-link {
  text-wrap: nowrap;
  color: $color-content-link;

  &:hover {
    color: $color-content-link-hover;
  }


}


.profile-svg, .messages-svg {
  height: 24px;
  width: 24px;
  margin-right: 7px;
  color: inherit;

}

.nav-svg {
  height: 22px;
  width: 22px;
  margin-right: 12px;
  color: inherit;
}

.css-2txnih {

  align-items: center;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  min-height: 40px;
  padding: 13px 48px 15px;
  position: relative;
  text-decoration: none;
  background-color: $color-dark;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  background-color: #FFFFFF;
  border: solid 5px #FFFFFF;
  border-radius: 4px;
  color: $color-dark;
  padding: 10px 43px 8px 43px;
  padding-left: 22px;
  padding-right: 22px;
}

.css-2txnih:disabled {
  cursor: default;
}

.css-2txnih:disabled {
  cursor: default;
}

@media (hover) {
  .css-2txnih:not(:disabled):hover {
    background-color: transparent;
    color: #FFFFFF;
  }

  .css-2txnih:not(:disabled):active {
    background-color: #7F9799;
    border-color: #7F9799;
    color: #FFFFFF;
  }
}

@media (hover: none) {
  .css-2txnih:not(:disabled):active {
    background-color: transparent;
    color: #FFFFFF;
  }
}

.css-2txnih:disabled {
  background-color: #406367;
  border-color: #406367;
  color: #7F9799;
  cursor: default;
}


.css-1povu0j {
  display: flex;
  align-items: center;
}


.dropdown-svg {
  height: 16px;
  width: 16px;
  margin-left: 7px;
  color: inherit;

  @media (min-width: 991px) {
    margin-left: 12px;
  }
}

.dropdown-lang-svg {
  height: 26px;
  width: 26px;
  margin-right: 7px;
  color: inherit;

  @media (min-width: 991px) {
    margin-left: 12px;
  }
}

.profile-dropdown-container, .language-dropdown-container {
  width: 225px;

  position: absolute;
  z-index: 20;
  transition: all 0.3s ease-in-out 0s;
  transform-origin: center top;
  transform: scaleY(0);
  margin-top: 12px;
  top: 10px;
  right: 24px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.hidden-element {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.visible-element {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}


.language-dropdown-container {
  width: 70px;
  right: 0;
  @media (min-width: 768px) {
    width: 142px;
  }

}

.lang-name-text:hover {
  color: $color-content-link-hover;
}

.user-container {
  display: flex;
  align-items: center;
  padding: 16px 24px 8px;
}

.avatar-svg {
  height: 40px;
  width: 40px;
  margin-right: 14px;
  color: $color-dark;
}

.user-name {
  font-size: 16px;
  font-weight: 600;
  flex: 1 1 0%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $color-content-tertiary;

  @media (min-width: 768px) {
    color: $color-dark;
  }
}

.top-bar-dropdown-header {
  line-height: 22px;
  padding: 8px 24px;
  margin: 8px 0px 0px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(127, 151, 153);
}

.top-bar-dropdown-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.top-bar-dropdown-list a {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 24px;
  font-size: 14px;
  color: $color-dark;
}


.top-bar-dropdown-list a:hover {
  color: rgb(255, 255, 255);
  background-color: $color-dark; //#0d6efd
}

.top-bar-dropdown-list a:hover svg {
  color: rgb(255, 255, 255);
}

.css-1tkrd1m {
  height: 24px;
  width: 24px;
  margin-right: 6px;
  color: $color-dark;
}

/* Reset button styles */
.top-bar-dropdown-list button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
  text-align: left;
}

/* Apply the desired styles */
.top-bar-dropdown-list button {
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 24px;
  font-size: 14px;
  color: $color-dark;
  background-color: transparent; /* Make sure it's transparent */
}

.top-bar-dropdown-list button:hover {
  color: rgb(255, 255, 255);
  background-color: $color-dark; //#0d6efd;
}

.top-bar-dropdown-list button:hover svg {
  color: rgb(255, 255, 255);
}


.css-cl0zfg {
  padding: 4px 8px;
  background-color: rgb(242, 244, 245);
  border-radius: 12px;
  font-size: 12px;
  color: $color-dark;
  margin-left: 12px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 14px;
}

.logout-dropdown {
  border-top: 1px solid rgb(216, 224, 216);
  margin-top: 8px;
}

.logout-dropdown a {
  padding: 16px 24px;
}

.css-ftx73f {
  padding: 4px 8px;
  background-color: rgb(255, 206, 50);
  border-radius: 12px;
  font-size: 12px;
  color: $color-dark;
  margin-left: 12px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 14px;
}

.nav_burger_container {
  position: relative;
  display: inline-block;
  padding: 10px 10px 10px 0;
}


.nav_burger {
  display: block;
  position: relative;
  width: 20px;
  height: 12px;
  z-index: 1000;

  &:before, &:after {
    content: "";
    background-color: $color-content-link;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease 0S;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  & span {
    background-color: $color-content-link;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 5px;
    transform: scale(1);
    transition: all 0.3s ease 0S;
  }

  &.active:before {
    top: 5px;
    transform: rotate(45deg);
  }

  &.active:after {
    bottom: 5px;
    transform: rotate(-45deg);
  }

  &.active span {
    transform: scale(0);
  }


  @media (min-width: 992px) {
    display: none;
  }
}


.profile-mobile-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -100%;
  padding-top: 54px;
  left: 0;
  z-index: 999;
  background-color: $color-dark;
  transition: all 0.3s ease 0S;

  &.active {
    top: 0;
  }

  &.top-bar-dropdown-list a {
    color: $color-content-link;
  }
}

.profile-mobile-container .top-bar-dropdown-list a {
  color: $color-content-link;
}

.profile-mobile-container .nav-link {
  color: $color-content-link;
}


.nav-link {
  color: $color-accent;
  text-wrap: nowrap;

  &.active {
    font-weight: bold;
    color: $color-dark;
    border-bottom: 2px solid $color-dark;
  }

  &:hover {
    color: $color-dark;
  }

  &:visited(:not.active) {
    color: $color-accent;
  }

  &:active {
    color: $color-dark;
  }
}

.ogo-tooltip-inner {
  background-color: $color-dark !important;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-color: transparent !important;
  border-left-color: $color-dark !important;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-color: transparent !important;
  border-right-color: $color-dark !important;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-color: transparent !important;
  border-top-color: $color-dark !important;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-color: transparent !important;
  border-bottom-color: $color-dark !important;
}


.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 25px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}


/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(19, 50, 23, 0.6);
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .2s;
}

input:checked + .slider {
  background-color: $color-dark;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-dark;
}

input:checked + .slider:before {
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.edit-bot-icon {
  width: 25px;
  height: 25px;
  color: $color-dark;
  opacity: 0.7;
}

a.bot-name-link {
  text-decoration: none;
}


.bot-off:not(.group-off) {
  opacity: 0.7
}

.item-off {
  opacity: 0.7
}


.icon-btn, .icon-btn-180, .icon-btn-0, .icon-btn-w, .copy-btn {
  color: $color-dark;
  border: None;
  opacity: 1;
  background: none;
  cursor: pointer;

  transition: transform 0.2s linear;

  &:hover, {
    opacity: 0.7;
  }

}


.test {
  box-sizing: border-box;
}

.icon-btn-w {
  padding: 0 !important;
}


.icon-btn-180:hover {
  transform: rotate(180deg);
}

.icon-btn-0:hover {
  opacity: 1;

}

.icon-btn-0.transform {
  transform: rotate(180deg);
}

#buttons_bottom_container {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  background-color: $color-light-green-grey;
  z-index: 997;
}


.bottom-icon-btn {
  color: $color-dark;
  width: 35px;
  opacity: 1;
  background: transparent;


  &:not(:disabled):hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.7;
  }

}

.bottom-button-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $color-light-green-grey;
}


.bottom-button-text {
  margin-top: 0.25rem;
  font-size: 10px;
  text-align: center;
  font-weight: $font-weight-semi-bold;
  letter-spacing: $letter-spacing-sm;

}


.chat-user-avatar {
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.chat-user-avatar-img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}


.css-13zt7wj {
  color: $color-dark;
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
}

.account-data-item-container {
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}


.my-form-control-sm {
  min-height: 40px !important;

  @media (max-width: 576px) {
    width: 100% !important;
    border-radius: 0.25rem !important;

    .input-group & {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}


.my-form-control-lg {
  min-height: 40px !important;

  @media (max-width: 992px) {
    width: 100% !important;
    border-radius: 0.25rem !important;

    .input-group & {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

#pagination-container {
  padding-bottom: 6rem;
  @media only screen and (min-width: 768px) {
    padding-bottom: 0;
  }
}

.ogo-checkbox {
  border-color: $color-dark !important;
  transform: scale(1.5);
  cursor: pointer;

  &:checked {
    background-color: $color-dark !important;
  }

  &:focus {
    box-shadow: none !important
  }

  &:checked:focus {
    box-shadow: none !important
  }
}

.notification-settings-label {
  font-weight: 700;
  color: $color-dark;
  margin-bottom: 5px;


}

.notification-settings-label-container {
  margin-right: 1rem;

  @media (min-width: 576px) {
    margin-right: 0;
  }
}

.checkbox-container {
  margin-left: 3rem;

  @media (min-width: 576px) {
    margin-left: 5rem;
  }
}

.email-notification-label, .telegram-notification-label {
  font-weight: 700;
  color: $color-dark;
}

.email-notification-label {
  margin-left: -1rem;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
}

.telegram-notification-label {
  margin-left: 0.5rem;
  font-size: 14px;

  @media (min-width: 576px) {
    margin-left: 2rem;
    font-size: 16px;
  }
}

.notification-settings-row {
  border-bottom: 1px solid #d8dfe0;
  margin-bottom: 1rem;
}


.sticky {
  position: fixed;
  flex: 1 0 auto;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: $color-light-green-grey;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  width: 100vw;
  box-sizing: border-box;
  transform: translateY(-100%);
}

.sticky.show {
  transform: translateY(0);
}


.og-input {

  &:focus {
    border-color: $color-border-focus !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  }
}

.my-select2 {

  margin-bottom: 1rem !important;
  @media (min-width: 576px) {

    margin-bottom: 0 !important;
  }
}

.og-color {
  color: $color-dark;
}


.group-button:not(:last-child) {
  margin-right: 0.5rem;
}


.group-answer, .simple-group-answer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 7px;

}


.group-answer__delete {
  width: 25px;
  height: 25px;
  padding: 0;
  opacity: 0.6;


  border: 0;
  background: none;
  cursor: pointer;

  transition: transform 0.2s linear;

}

.group-answer__delete:hover {
  transform: rotate(45deg);
}


.big-switch {
  transform: scale(1.5)
}


.answers-header {
  position: sticky;
  top: 0;
}

#buttons {
  position: fixed;
  display: flex;
  bottom: -100px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 25px;
  opacity: 0;
  z-index: 1000;

}

#buttons-test {
  position: relative;
}


#bottom-alerts {
  min-width: 360px;
  position: fixed;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
}

.my-wrapper {
  position: fixed;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

a.rules-link {
  text-decoration: none;
  color: $color-dark;

  &:hover {
    color: white;
    background-color: $color-dark;
  }
}


a.modal-link {
  text-decoration: underline;

  color: $color-dark;

  &:hover {
    text-decoration: none;
    color: $color-dark;
  }
}

.rule-article-title {
  color: $color-dark;
  font-weight: 400;
}


.rules-heading-container {
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 3rem;
  }
}

ul.footer-nav-links-container {
  padding: 0;
  margin-bottom: 1rem;
  font-size: $font-size-16;
  list-style: none;
  text-align: center;

  @media (min-width: 576px) {
    margin: 0;

    text-align: left;
  }
}

.footer-social-icon-link {
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
}


.footer-header {
  font-weight: $font-weight-medium;
  font-size: $font-size-14;
  color: $color-dark;
  text-transform: uppercase;

  margin-bottom: 1rem;

  text-align: center;

  @media (min-width: 576px) {

    text-align: left;
  }
}

.footer-social-icon {
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-dark;
  opacity: .85;


  & svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  &:hover {
    opacity: 1;
  }


}

.social-icons-container {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;

  @media (min-width: 576px) {
    justify-content: start;
    margin-bottom: 0;
  }


}

.footer-column {
  margin-bottom: 1.4rem;
  @media (min-width: 576px) {

    margin-bottom: 0;
  }

}

.copyright {
  text-align: center;

  @media (min-width: 576px) {

    text-align: right;
  }
}


.olx-gpt-logo-container .svg-colors {
  fill: $color-content-link;
  stroke: $color-content-link;
}

.contacts-icon {
  width: 53px;
  height: 53px;
  color: $color-dark;
}

a.contacts-link {
  text-decoration: none;
  color: $color-dark;

  &:hover {
    color: $color-dark;
  }
}

.custom-alert {
  max-width: 90%;
  margin: 0 auto;
}

.display-alert-text {
  font-weight: bold;
  overflow-wrap: break-word;
  font-size: .7rem;
  word-wrap: break-word !important;
  white-space: normal !important;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
}


#scroll-down, #scroll-up {
  //bottom: 159px;

  bottom: 30vh;

  @media (min-width: 576px) {
    bottom: 30vh;
  }

}

#collapse-all {
  //bottom: 229px;
  bottom: calc(30vh + 70px);
  @media (min-width: 576px) {
    bottom: calc(30vh + 70px);
  }
}


.scroll-btn {
  color: $color-white;
  width: 39px;
  height: 39px;
  transition: opacity 0.3s ease;

  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}


#scroll-up, #scroll-down, #collapse-all {
  position: fixed;
  top: auto;
  //right: calc(50vw - 34px);

  right: 27px;

  z-index: 10000;

  @media (min-width: 576px) {
    right: 27px;
  }


}

#guide-btn, #scroll-up, #scroll-down, #collapse-all {
  display: flex;
  width: 60px;
  height: 60px;
  //margin-right: 7px;
  justify-content: center;
  align-items: center;
  background-color: $color-dark;
  border-radius: 50%;
  cursor: pointer;

}

.guide-icon {
  color: $color-white;
  width: 39px;
  height: 39px;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
}


.driver-overlay {
  z-index: 2000000001 !important;
}

.driver-popover {
  z-index: 2000000002 !important;
}

.bottom-adjust {
  position: fixed;
  z-index: 10000;
  top: auto;
  left: 20px;
  right: auto;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


}

.bot-details {
  margin-bottom: 1.5rem;
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.dropdown-item {
  color: $color-dark;

  &:hover,
  &:focus {
    color: white;
    background-color: $color-dark;
  }

  &.active,
  &:active {
    color: white;
    background-color: $color-dark;
  }

  &.disabled,
  &:disabled {
    color: $color-accent;
  }
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Соотношение сторон 16:9 (9 / 16 * 100) */
  overflow: hidden;
}

.responsive-video {
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  background: $color-dark;
}

.video-poster {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color-dark, 0.9); /* Полупрозрачный фон */
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1;
  flex-direction: column;
}

.video-poster-text {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;

  @media (min-width: 576px) {
    font-size: 3rem;
  }
}

.play-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  cursor: pointer;

  @media (min-width: 576px) {
    font-size: 3rem;
  }
}


.video-container.playing .video-poster {
  display: none; /* Скрываем постер, когда видео начинает играть */
}

.video-container.playing .responsive-video {
  opacity: 1; /* Делаем видео видимым */
}