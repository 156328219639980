
.m-0 {
    margin: 0!important
}

.mt-0,.my-0 {
    margin-top: 0!important
}

.mr-0,.mx-0 {
    margin-right: 0!important
}

.mb-0,.my-0 {
    margin-bottom: 0!important
}

.ml-0,.mx-0 {
    margin-left: 0!important
}

.m-1 {
    margin: .25rem!important
}

.mt-1,.my-1 {
    margin-top: .25rem!important
}

.mr-1,.mx-1 {
    margin-right: .25rem!important
}

.mb-1,.my-1 {
    margin-bottom: .25rem!important
}

.ml-1,.mx-1 {
    margin-left: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.mt-2,.my-2 {
    margin-top: .5rem!important
}

.mr-2,.mx-2 {
    margin-right: .5rem!important
}

.mb-2,.my-2 {
    margin-bottom: .5rem!important
}

.ml-2,.mx-2 {
    margin-left: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.mt-3,.my-3 {
    margin-top: 1rem!important
}

.mr-3,.mx-3 {
    margin-right: 1rem!important
}

.mb-3,.my-3 {
    margin-bottom: 1rem!important
}

.ml-3,.mx-3 {
    margin-left: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.mt-4,.my-4 {
    margin-top: 1.5rem!important
}

.mr-4,.mx-4 {
    margin-right: 1.5rem!important
}

.mb-4,.my-4 {
    margin-bottom: 1.5rem!important
}

.ml-4,.mx-4 {
    margin-left: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.mt-5,.my-5 {
    margin-top: 3rem!important
}

.mr-5,.mx-5 {
    margin-right: 3rem!important
}

.mb-5,.my-5 {
    margin-bottom: 3rem!important
}

.ml-5,.mx-5 {
    margin-left: 3rem!important
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis:0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis:0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis:0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis:0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}