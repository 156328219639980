@import "variables";


.form-control:focus, .input-group *:focus {
  border-color: $color-border-focus !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}


.form-check-input {
  border-color: $color-dark;

  &:checked {
    background-color: $color-dark;
    border-color: $color-dark;
  }

  &:focus {

    border-color: $color-dark;

    box-shadow: none
  }

}

.input-group-text {
  background-color: $color-highlighted-background;
  border: 1px solid #cfdace;
}