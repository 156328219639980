@import "variables";


.page-link {
  color: $color-dark!important;
  &:focus {
    box-shadow: none!important;
  }
}

.page-item.active .page-link {
  color: #fff!important;
  background-color: $color-dark!important;
  border-color: $color-dark!important;
}


.page-limit:not(.page-limit-selected) {
  color: rgb(34, 41, 33) !important;
}

.page-limit:hover:not(.page-limit-selected) {
  z-index: 2;
  background-color: #eaefe9 !important;
  border-color: #dee6de !important;
}

.page-limit-selected {
  color: #fff;
  background-color: $color-dark!important;
  border-color: $color-dark!important;
}



.page-limit {
    cursor: pointer;
    margin-left: 8px;
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
    color: rgb(33, 41, 34);
    border-radius: 17%;
    border: 2px solid transparent;
}

.page-limit:hover:not(.page-limit-selected) {
    z-index: 2;
    background-color: #e9efe9;
    border-color: #dee6de
}

.page-limit-selected {
    border-radius: 17%;
    cursor: pointer;
    margin-left: 8px;
    width: 31px;
    height: 31px;
    min-width: 31px;
    min-height: 31px;
    color: #fff;
    background-color: $color-dark;
    border-color: $color-dark;
}