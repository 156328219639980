@import "../variables";


.outline-button {

  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  min-height: 40px;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  background-color: transparent;
  border: 2px solid $color-dark;
  border-radius: 4px;
  color: $color-dark;
  padding: 6px 7px;
  white-space: nowrap;
  width: 100%;

  @media (min-width: 576px) {
    width: auto;
  }


  &:not(:disabled):hover {
    border-width: 5px;
    padding: 5px 4px;
    text-decoration: none;
    color: $color-dark;
  }

  &:not(:disabled):active {
    background-color: rgb(132, 153, 127);
    border-color: rgb(128, 153, 127);
    color: rgb(255, 255, 255);
  }

  &:disabled {
    border-color: rgb(216, 224, 216);
    color: rgb(127, 153, 137);
    cursor: default;
  }
}
